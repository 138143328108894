import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import futurelabsgrey from './assets/futurefoodgrey.png';
import futureIdeasLogo from './assets/futureIdeas.png';
import futurePredictionsLogo from './assets/FuturePredictions.png';
import futureBitcoinLogo from './assets/futureBitcoin.png';

// Simulate fetching historical and predicted Bitcoin data
const fetchBitcoinData = () => {
  const currentDate = new Date();
  const historicalData = [...Array(30)].map((_, i) => ({
    date: new Date(currentDate - (29 - i) * 24 * 60 * 60 * 1000).toISOString().split('T')[0],
    price: Math.floor(Math.random() * 10000) + 20000,
    type: 'historical'
  }));
  const predictedData = [...Array(7)].map((_, i) => ({
    date: new Date(currentDate.getTime() + (i + 1) * 24 * 60 * 60 * 1000).toISOString().split('T')[0],
    price: Math.floor(Math.random() * 10000) + 20000,
    type: 'predicted'
  }));
  return [...historicalData, ...predictedData];
};

const Card = ({ children, className }) => (
  <div className={`bg-gray-800 bg-opacity-30 backdrop-filter backdrop-blur-lg rounded-lg p-6 shadow-lg ${className}`}>
    {children}
  </div>
);

function FuturePredictionsPage() {
  const [bitcoinData, setBitcoinData] = useState([]);
  const [predictionPrice, setPredictionPrice] = useState('');
  const [predictionTime, setPredictionTime] = useState('');
  const [predictionAmount, setPredictionAmount] = useState('');
  const [predictions, setPredictions] = useState([]);

  useEffect(() => {
    setBitcoinData(fetchBitcoinData());
  }, []);

  const submitPrediction = () => {
    const currentTime = new Date();
    const predictionTimeDate = new Date(predictionTime);
    const timeDifference = (predictionTimeDate - currentTime) / (1000 * 60); // difference in minutes

    if (timeDifference < 5) {
      alert("Prediction time must be at least 5 minutes in the future.");
      return;
    }

    const newPrediction = {
      price: parseFloat(predictionPrice),
      time: predictionTime,
      amount: parseFloat(predictionAmount),
      id: Date.now()
    };

    const existingPrediction = predictions.find(p => p.price === newPrediction.price);
    if (existingPrediction) {
      const potentialWin = existingPrediction.amount * 2;
      const potentialLoss = newPrediction.amount;
      alert(`There's an existing prediction for this price. You stand to win $${potentialWin} or lose $${potentialLoss}.`);
    }

    setPredictions([...predictions, newPrediction]);
    setPredictionPrice('');
    setPredictionTime('');
    setPredictionAmount('');
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-900 to-black text-white font-sans">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <header className="py-6 sm:py-10 flex justify-between items-center border-b border-gray-800">
          <img 
            src={futurePredictionsLogo} 
            alt="Future Predictions Logo" 
            className="w-24 h-auto sm:w-[120px] sm:h-[76px] object-contain"
          />
          <Link to="/" className="text-gray-400 hover:text-gray-300 transition-colors duration-200 text-sm sm:text-base">
            By Future Labs
          </Link>
        </header>

        <main className="py-8 space-y-8">
          <h1 className="text-3xl sm:text-4xl font-bold text-center bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-600">
            Future Prediction Engines
          </h1>

          <Card>
            <h2 className="text-xl font-semibold mb-4">Prediction Engines</h2>
            <div className="flex justify-center">
              <Link 
                to="/bitcoin-prediction"
                className="bg-blue-600 hover:bg-blue-700 px-6 py-3 rounded-lg text-white text-sm sm:text-base shadow-lg hover:shadow-xl transition-all duration-200 text-center"
              >
                Bitcoin Core Predictions
              </Link>
            </div>
          </Card>
        </main>

        <footer className="py-6 sm:py-10 border-t border-gray-800">
          <p className="text-gray-400 text-sm sm:text-base mb-2 text-left">© 2024 Future Labs.</p>
          <p className="text-gray-400 text-sm sm:text-base max-w-[300px] text-left">
            Mission: We want to help ourselves and others enjoy art and science while having not a good life, but a fucking great one!
          </p>
        </footer>
      </div>
    </div>
  );
}

function BitcoinCorePage() {
  const [bitcoinData, setBitcoinData] = useState([]);
  const [predictionPrice, setPredictionPrice] = useState('');
  const [predictionTime, setPredictionTime] = useState('');
  const [predictionAmount, setPredictionAmount] = useState('');
  const [predictions, setPredictions] = useState([]);

  useEffect(() => {
    setBitcoinData(fetchBitcoinData());
  }, []);

  const submitPrediction = () => {
    // ... (same as in FuturePredictionsPage)
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-900 to-black text-white font-sans">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <header className="py-6 sm:py-10 flex justify-between items-center border-b border-gray-800">
          <img 
            src={futureBitcoinLogo} 
            alt="Future Bitcoin Logo" 
            className="w-24 h-auto sm:w-[120px] sm:h-[76px] object-contain"
          />
          <Link to="/" className="text-gray-400 hover:text-gray-300 transition-colors duration-200 text-sm sm:text-base">
            By Future Labs
          </Link>
        </header>

        <main className="py-8 space-y-8">
          <h1 className="text-3xl sm:text-4xl font-bold text-center bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-600">
            Bitcoin Core Prediction Engine
          </h1>

          {/* ... (same content as FuturePredictionsPage) */}
        </main>

        <footer className="py-6 sm:py-10 border-t border-gray-800">
          <p className="text-gray-400 text-sm sm:text-base mb-2 text-left">© 2024 Future Labs.</p>
          <p className="text-gray-400 text-sm sm:text-base max-w-[300px] text-left">
            Mission: We want to help ourselves and others enjoy art and science while having not a good life, but a fucking great one!
          </p>
        </footer>
      </div>
    </div>
  );
}

function FutureIdeasPage() {
  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-900 to-black text-white font-sans">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <header className="py-6 sm:py-10 flex justify-between items-center border-b border-gray-800">
          <img 
            src={futureIdeasLogo} 
            alt="Future Ideas Logo" 
            className="w-24 h-auto sm:w-[120px] sm:h-[76px] object-contain"
          />
          <Link to="/" className="text-gray-400 hover:text-gray-300 transition-colors duration-200 text-sm sm:text-base">
            By Future Labs
          </Link>
        </header>

        <main className="py-8 space-y-8">
          <h1 className="text-3xl sm:text-4xl font-bold text-center bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-600">
            Future Ideas Lab
          </h1>

          {/* Empty middle section */}
          <div className="h-64 flex items-center justify-center">
            <p className="text-gray-400">Content coming soon...</p>
          </div>
        </main>

        <footer className="py-6 sm:py-10 border-t border-gray-800">
          <p className="text-gray-400 text-sm sm:text-base mb-2 text-left">© 2024 Future Labs.</p>
          <p className="text-gray-400 text-sm sm:text-base max-w-[300px] text-left">
            Mission: We want to help ourselves and others enjoy art and science while having not a good life, but a fucking great one!
          </p>
        </footer>
      </div>
    </div>
  );
}

export { FuturePredictionsPage, BitcoinCorePage, FutureIdeasPage };