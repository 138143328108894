import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Search, Send, ThumbsUp, ThumbsDown, DollarSign, ChevronDown, ChevronUp, Bookmark } from 'lucide-react';
import { collection, addDoc, query, getDocs, updateDoc, doc, setDoc } from 'firebase/firestore';
import { getAuth, signInWithPopup, GoogleAuthProvider, onAuthStateChanged } from 'firebase/auth';
import { LineChart, Line, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
import OpenAI from 'openai';
import futureIdeasLogo from './assets/futureIdeas.png';
import { app, db } from './firebaseConfig'; // Import app and db from your existing firebaseConfig.js

const auth = getAuth(app);
const provider = new GoogleAuthProvider();
const MANAGER_ADDRESS = "Ai5oNL8qvQGE2dbYjzXahYvT44KoQ73zrUwsoPNr6PNB";

const openai = new OpenAI({
  apiKey: 'sk-proj-t17A9sPegzSf7THV3YN7T3BlbkFJoJItXaELPAniVG7hbzTk',
  dangerouslyAllowBrowser: true
});

function FutureIdeasPage() {
  const [inputValue, setInputValue] = useState('');
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [dynamicFilters, setDynamicFilters] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [expandedIdeas, setExpandedIdeas] = useState([]);
  const [ideas, setIdeas] = useState([]);
  const [viewMode, setViewMode] = useState('recent');
  const [timeRemaining, setTimeRemaining] = useState('');
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [ideasSummary, setIdeasSummary] = useState('');
  const [user, setUser] = useState(null);
  const [savedIdeas, setSavedIdeas] = useState({});

  const prizeAmount = 1000;

  useEffect(() => {
    const timer = setInterval(() => {
      const now = new Date();
      const endOfNextWeek = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 7 - now.getDay() + 7, 23, 59, 59);
      const diff = endOfNextWeek - now;
      
      const days = Math.floor(diff / (1000 * 60 * 60 * 24));
      const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((diff % (1000 * 60)) / 1000);
      
      setTimeRemaining(`${days}d ${hours}h ${minutes}m ${seconds}s`);
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
      setIsSignedIn(!!user);
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    fetchIdeas();
  }, []);

  const categories = [
    "Business", "Technology", "Science", "Arts", "Health",
    "Education", "Environment", "Social", "Politics", "Philosophy"
  ];

  const fetchIdeas = async () => {
    const ideasRef = collection(db, "ideas");
    const querySnapshot = await getDocs(ideasRef);
    let fetchedIdeas = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

    if (!fetchedIdeas.some(idea => idea.text.includes("Bitcoin"))) {
      const bitcoinIdea = await addInitialIdea("Bitcoin");
      fetchedIdeas.unshift(bitcoinIdea);
    }

    setIdeas(fetchedIdeas);
    updateRewardDistribution(fetchedIdeas);
    updateIdeasSummary(fetchedIdeas);
  };

  const updateRewardDistribution = (currentIdeas) => {
    const totalVotes = currentIdeas.reduce((sum, idea) => sum + idea.upvotes - idea.downvotes, 0);
    const updatedIdeas = currentIdeas.map(idea => {
      const newReward = totalVotes === 0 ? 0 : Math.round((idea.upvotes - idea.downvotes) / totalVotes * prizeAmount);
      const updatedRewardHistory = [
        ...(idea.rewardHistory || []),
        { date: new Date().toISOString(), reward: newReward }
      ];
      return {
        ...idea,
        currentReward: newReward,
        rewardHistory: updatedRewardHistory
      };
    });
    setIdeas(updatedIdeas);
    updatedIdeas.forEach(idea => updateIdeaInFirestore(idea));
  };

  const updateIdeaInFirestore = async (idea) => {
    const ideaRef = doc(db, "ideas", idea.id);
    await setDoc(ideaRef, idea, { merge: true });
  };

  const updateIdeasSummary = async (currentIdeas) => {
    const ideaTexts = currentIdeas.map(idea => idea.text).join('\n');
    try {
      const summaryResponse = await openai.chat.completions.create({
        model: "gpt-4-turbo",
        messages: [
          { role: "system", content: "You are a helpful assistant that summarizes cryptocurrency and financial ideas into a single, concise core idea." },
          { role: "user", content: `Summarize these ideas into a single, concise core idea, focusing on key points and avoiding symbols: ${ideaTexts}` }
        ],
        max_tokens: 100,
      });
      const summary = summaryResponse.choices[0].message.content;
      setIdeasSummary(summary);

      const updatedIdeas = adjustAIWeightings(currentIdeas, summary);
      setIdeas(updatedIdeas);
      updateRewardDistribution(updatedIdeas);
    } catch (error) {
      console.error("Error generating summary:", error);
      setIdeasSummary("Unable to generate summary at this time.");
    }
  };

  const adjustAIWeightings = (currentIdeas, summary) => {
    const summaryLower = summary.toLowerCase();
    return currentIdeas.map(idea => {
      const ideaTextLower = idea.text.toLowerCase();
      if (summaryLower.includes(ideaTextLower)) {
        const aiWeighting = idea.aiWeighting || 1;
        const newAiWeighting = aiWeighting * 1.1;
        return {
          ...idea,
          aiWeighting: newAiWeighting,
          aiWeightingHistory: [
            ...(idea.aiWeightingHistory || []),
            {
              date: new Date().toISOString(),
              weightingChange: ((newAiWeighting / aiWeighting - 1) * 100).toFixed(2)
            }
          ]
        };
      }
      return idea;
    });
  };

  const addInitialIdea = async (coinName) => {
    const newIdea = {
      text: coinName,
      upvotes: 0,
      downvotes: 0,
      currentReward: 0,
      categories: ["Business", "Technology"],
      timestamp: new Date().toISOString(),
      walletAddress: MANAGER_ADDRESS,
      rewardHistory: [{ date: new Date().toISOString(), reward: 0 }],
      aiWeighting: 1,
      aiWeightingHistory: []
    };

    const docRef = await addDoc(collection(db, "ideas"), newIdea);
    return { id: docRef.id, ...newIdea };
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleCategoryChange = (category) => {
    setSelectedCategories(prev => 
      prev.includes(category)
        ? prev.filter(c => c !== category)
        : [...prev, category]
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (inputValue.trim()) {
      try {
        let categories = selectedCategories;
        if (categories.length === 0) {
          categories = await suggestCategories(inputValue);
        }

        const newIdea = {
          text: inputValue.trim(),
          upvotes: 0,
          downvotes: 0,
          currentReward: 0,
          categories: categories,
          timestamp: new Date().toISOString(),
          walletAddress: user ? user.uid : 'AnonymousUser',
          rewardHistory: [{ date: new Date().toISOString(), reward: 0 }],
          aiWeighting: 1,
          aiWeightingHistory: []
        };

        await addDoc(collection(db, "ideas"), newIdea);

        setInputValue('');
        setSelectedCategories([]);
        fetchIdeas();
      } catch (error) {
        console.error("Error submitting idea:", error);
        alert("Failed to submit idea. Please try again.");
      }
    }
  };

  const suggestCategories = async (ideaText) => {
    try {
      const response = await openai.chat.completions.create({
        model: "gpt-4-turbo",
        messages: [
          { role: "system", content: "You are an assistant that suggests relevant categories for ideas. Choose from these categories: Business, Technology, Science, Arts, Health, Education, Environment, Social, Politics, Philosophy." },
          { role: "user", content: `Suggest one or more relevant categories for this idea: ${ideaText}` }
        ],
        max_tokens: 50,
      });
      const suggestedCategories = response.choices[0].message.content.split(',').map(cat => cat.trim());
      return suggestedCategories.filter(cat => categories.includes(cat));
    } catch (error) {
      console.error("Error suggesting categories:", error);
      return ["General"];
    }
  };

  const handleVote = async (id, isUpvote) => {
    if (!isSignedIn) {
      alert("Please sign in to vote.");
      return;
    }

    try {
      const ideaDoc = doc(db, "ideas", id);
      const idea = ideas.find(i => i.id === id);
      const updatedIdea = {
        upvotes: isUpvote ? idea.upvotes + 1 : idea.upvotes,
        downvotes: !isUpvote ? idea.downvotes + 1 : idea.downvotes,
      };
      await updateDoc(ideaDoc, updatedIdea);

      fetchIdeas();
    } catch (error) {
      console.error("Error voting:", error);
      alert("Failed to vote. Please try again.");
    }
  };

  const handleSave = async (id) => {
    if (!isSignedIn) {
      alert("Please sign in to save ideas.");
      return;
    }

    setSavedIdeas(prev => ({
      ...prev,
      [id]: !prev[id]
    }));

    console.log(`Idea ${id} ${savedIdeas[id] ? 'unsaved' : 'saved'}`);
  };

  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleString();
  };

  const toggleFilter = async (category) => {
    const updatedFilters = selectedFilters.includes(category)
      ? selectedFilters.filter(c => c !== category)
      : [...selectedFilters, category];
    
    setSelectedFilters(updatedFilters);

    if (updatedFilters.includes(category)) {
      const relevantIdeas = ideas.filter(idea => idea.categories.includes(category));
      const relevantTexts = relevantIdeas.map(idea => idea.text).join('\n');
      
      try {
        const response = await openai.chat.completions.create({
          model: "gpt-4-turbo",
          messages: [
            { role: "system", content: "You are an assistant that generates relevant sub-filters for a category based on given ideas." },
            { role: "user", content: `Generate 5 relevant sub-filters for the ${category} category based on these ideas:\n${relevantTexts}` }
          ],
          max_tokens: 100,
        });
        const newFilters = response.choices[0].message.content.split(',').map(filter => filter.trim());
        setDynamicFilters(newFilters);
      } catch (error) {
        console.error("Error generating dynamic filters:", error);
        setDynamicFilters([]);
      }
    } else {
      setDynamicFilters([]);
    }
  };

  const filterIdeas = (ideas) => {
    return ideas.filter(idea => 
      (selectedFilters.length === 0 || idea.categories.some(cat => selectedFilters.includes(cat))) &&
      (searchQuery === '' || idea.text.toLowerCase().includes(searchQuery.toLowerCase()))
    );
  };

  const toggleExpand = (id) => {
    setExpandedIdeas(prev =>
      prev.includes(id) ? prev.filter(i => i !== id) : [...prev, id]
    );
  };

  const renderIdea = (idea) => (
    <li key={idea.id} className="bg-gray-800 rounded-md p-3 flex flex-col text-sm mb-2">
      <div className="flex justify-between items-center mb-2">
        <span className="mr-2 flex-grow text-left">{idea.text}</span>
        <div className="flex items-center space-x-2 whitespace-nowrap">
          <button onClick={() => handleVote(idea.id, true)} className="text-green-500">
            <ThumbsUp size={18} />
          </button>
          <span>{idea.upvotes - idea.downvotes}</span>
          <button onClick={() => handleVote(idea.id, false)} className="text-red-500">
            <ThumbsDown size={18} />
          </button>
          <span className="text-yellow-500 flex items-center">
            <DollarSign size={18} />
            <span>{idea.currentReward}</span>
          </span>
          <button onClick={() => handleSave(idea.id)} className={`${savedIdeas[idea.id] ? 'text-blue-500' : 'text-gray-500'}`}>
            <Bookmark size={18} fill={savedIdeas[idea.id] ? 'currentColor' : 'none'} />
          </button>
          <button onClick={() => toggleExpand(idea.id)} className="text-gray-400">
            {expandedIdeas.includes(idea.id) ? <ChevronUp size={18} /> : <ChevronDown size={18} />}
          </button>
        </div>
      </div>
      <div className="text-xs text-gray-400 flex justify-between items-center">
        <div className="flex flex-wrap gap-1">
          {idea.categories.map(category => (
            <span key={category} className="bg-gray-700 px-2 py-1 rounded-full">{category}</span>
          ))}
        </div>
        <span>{formatTimestamp(idea.timestamp)}</span>
      </div>
      {expandedIdeas.includes(idea.id) && (
        <div className="mt-2">
          <p className="text-sm text-gray-300">AI Weighting: {((idea.aiWeighting || 1) * 100).toFixed(2)}%</p>
          <ResponsiveContainer width="100%" height={200}>
            <LineChart data={idea.rewardHistory}>
              <XAxis dataKey="date" />
              <YAxis />
              <Tooltip />
              <Line type="monotone" dataKey="reward" stroke="#8884d8" />
            </LineChart>
          </ResponsiveContainer>
          <div className="mt-2">
            <h4 className="text-sm font-semibold mb-1">AI Weighting History:</h4>
            <ul className="text-xs space-y-1">
              {idea.aiWeightingHistory && idea.aiWeightingHistory.map((update, index) => (
                <li key={index} className="flex justify-between">
                  <span>{formatTimestamp(update.date)}</span>
                  <span className={update.weightingChange > 0 ? 'text-green-500' : 'text-red-500'}>
                    {update.weightingChange > 0 ? '+' : ''}{update.weightingChange}%
                  </span>
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
    </li>
  );

  const sortIdeas = (ideas) => {
    switch (viewMode) {
      case 'trending':
        return [...ideas].sort((a, b) => (b.upvotes - b.downvotes) - (a.upvotes - a.downvotes));
      case 'valuable':
        return [...ideas].sort((a, b) => b.currentReward - a.currentReward);
      case 'recent':
      default:
        return [...ideas].sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
    }
  };

  const handleSignIn = async () => {
    try {
      const result = await signInWithPopup(auth, provider);
      setUser(result.user);
      setIsSignedIn(true);
    } catch (error) {
      console.error("Error signing in with Google:", error);
      alert("Failed to sign in. Please try again.");
    }
  };

  const handleSignOut = async () => {
    try {
      await auth.signOut();
      setUser(null);
      setIsSignedIn(false);
    } catch (error) {
      console.error("Error signing out:", error);
      alert("Failed to sign out. Please try again.");
    }
  };

  return (
    <div className="min-h-screen bg-black text-white krona-one-regular flex flex-col">
      <header className="p-4 sm:p-6 md:p-8 flex justify-between items-center">
        <img 
          src={futureIdeasLogo} 
          alt="Future Ideas Logo" 
          className="w-20 h-auto sm:w-24 md:w-28 object-contain"
        />
        <Link to="/" className="text-gray-500 text-xs sm:text-sm">
          By Future Labs
        </Link>
      </header>
      
      <main className="flex-grow flex flex-col p-4 sm:p-6 md:p-8 gap-6 max-w-6xl mx-auto w-full">
        <div className="w-full">
          <div className="mb-4">
            <h1 className="text-lg sm:text-xl font-bold text-center">
              What is the most valuable business idea right now?
            </h1>
            <p className="text-sm text-gray-400 mt-2 text-center">
              Time remaining: {timeRemaining}
            </p>
            <p className="text-sm text-yellow-400 mt-1 text-center">
              Reward Prize: ${prizeAmount}
            </p>
            {isSignedIn ? (
              <div className="mt-4">
                <p className="text-sm text-green-400">Signed in as: {user.email}</p>
                <button
                  onClick={handleSignOut}
                  className="bg-red-500 hover:bg-red-600 text-white px-4 py-2 rounded-md transition duration-300 text-sm mt-2 w-full"
                >
                  Sign Out
                </button>
              </div>
            ) : (
              <button
                onClick={handleSignIn}
                className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-md transition duration-300 text-sm mt-4 w-full"
              >
                Sign in with Google
              </button>
            )}
            <div className="bg-gray-800 p-4 rounded-md mt-4">
              <h2 className="text-sm font-semibold mb-2">The most valuable idea in the world right now:</h2>
              <p className="text-sm text-left">{ideasSummary}</p>
            </div>
          </div>

          <div className="relative mb-4">
            <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={18} />
            <input
              type="text"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              placeholder="Search ideas..."
              className="w-full bg-gray-800 text-white border border-gray-700 rounded-md pl-10 pr-3 py-2 text-sm focus:outline-none focus:border-blue-500"
            />
          </div>

          <div className="overflow-x-auto scrollbar-hide mb-4">
            <div className="flex space-x-2 py-2">
              {categories.map(category => (
                <button
                  key={category}
                  type="button"
                  onClick={() => toggleFilter(category)}
                  className={`px-3 py-1 text-xs rounded-full whitespace-nowrap ${
                    selectedFilters.includes(category) ? 'bg-blue-500 text-white' : 'bg-gray-700 text-gray-300'
                  }`}
                >
                  {category}
                </button>
              ))}
            </div>
            {dynamicFilters.length > 0 && (
              <div className="flex space-x-2 py-2 mt-2">
                {dynamicFilters.map((filter, index) => (
                  <button
                    key={index}
                    type="button"
                    onClick={() => toggleFilter(filter)}
                    className={`px-3 py-1 text-xs rounded-full whitespace-nowrap ${
                      selectedFilters.includes(filter) ? 'bg-blue-500 text-white' : 'bg-gray-700 text-gray-300'
                    }`}
                  >
                    {filter}
                  </button>
                ))}
              </div>
            )}
          </div>

          <div className="mb-4 flex justify-between items-center">
            <div className="space-x-2">
              <button
                onClick={() => setViewMode('recent')}
                className={`px-3 py-1 text-xs rounded ${viewMode === 'recent' ? 'bg-blue-500 text-white' : 'bg-gray-700 text-gray-300'}`}
              >
                Recent
              </button>
              <button
                onClick={() => setViewMode('trending')}
                className={`px-3 py-1 text-xs rounded ${viewMode === 'trending' ? 'bg-blue-500 text-white' : 'bg-gray-700 text-gray-300'}`}
              >
                Trending
              </button>
              <button
                onClick={() => setViewMode('valuable')}
                className={`px-3 py-1 text-xs rounded ${viewMode === 'valuable' ? 'bg-blue-500 text-white' : 'bg-gray-700 text-gray-300'}`}
              >
                Most Valuable
              </button>
            </div>
          </div>

          <div className="flex-grow">
            <ul className="space-y-3">
              {sortIdeas(filterIdeas(ideas)).map(idea => renderIdea(idea))}
            </ul>
          </div>

          <div className="mt-4">
            <form onSubmit={handleSubmit} className="flex flex-col space-y-2">
              <input
                type="text"
                value={inputValue}
                onChange={handleInputChange}
                placeholder="Enter your idea here..."
                className="w-full bg-gray-800 text-white border border-gray-700 rounded-md px-3 py-2 text-sm focus:outline-none focus:border-blue-500"
              />
              <div className="flex flex-wrap gap-2">
                {categories.map(category => (
                  <button
                    key={category}
                    type="button"
                    onClick={() => handleCategoryChange(category)}
                    className={`px-2 py-1 text-xs rounded-full ${
                      selectedCategories.includes(category) ? 'bg-blue-500 text-white' : 'bg-gray-700 text-gray-300'
                    }`}
                  >
                    {category}
                  </button>
                ))}
              </div>
              <button
                type="submit"
                className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-md transition duration-300 text-sm"
              >
                <Send size={18} className="inline-block mr-2" />
                Submit Idea
              </button>
            </form>
          </div>
        </div>
      </main>
      
      <footer className="p-4 sm:p-6 md:p-8 flex flex-col items-start">
        <p className="text-[#535353] text-xs sm:text-sm mb-1">© 2024 Future Labs.</p>
        <p className="text-[#535353] text-xs sm:text-sm max-w-xs text-left">
          Mission: We want to help ourselves and others enjoy art and science while having not a good life, but a fucking great one!
        </p>
      </footer>
    </div>
  );
}

export default FutureIdeasPage;