import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Play, Pause, Mic, Camera } from 'lucide-react';
import OpenAI from 'openai';
import { getStorage, ref, uploadString } from "firebase/storage";
import { app } from './firebaseConfig'; // Make sure this path is correct

const openai = new OpenAI({
  apiKey: 'sk-proj-t17A9sPegzSf7THV3YN7T3BlbkFJoJItXaELPAniVG7hbzTk',
  dangerouslyAllowBrowser: true
});

const ASSISTANT_ID = 'asst_Ei0blYfLVJU7BFfBlvCbX54s';

const AudioPlayer = ({ audioSrc, onTranscriptionUpdate, onVideoStreamChange }) => {
  const navigate = useNavigate();
  const [isRecording, setIsRecording] = useState(false);
  const [transcription, setTranscription] = useState('');
  const [isPlaying, setIsPlaying] = useState(false);
  const [cameraOn, setCameraOn] = useState(false);
  const [analysisResult, setAnalysisResult] = useState('');
  const audioRef = useRef(null);
  const ttsAudioRef = useRef(null);
  const videoRef = useRef(null);
  const streamRef = useRef(null);

  const storage = getStorage(app);

  useEffect(() => {
    console.log("AudioPlayer component mounted");
  }, []);

  useEffect(() => {
    if (analysisResult) {
      generateAndPlaySpeech(analysisResult);
    }
  }, [analysisResult]);

  const toggleCamera = async () => {
    if (cameraOn) {
      // Turn off the camera
      if (streamRef.current) {
        streamRef.current.getTracks().forEach(track => track.stop());
      }
      setCameraOn(false);
      onVideoStreamChange(null);
    } else {
      // Turn on the camera
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
        videoRef.current.srcObject = stream;
        streamRef.current = stream;
        setCameraOn(true);
        onVideoStreamChange(stream);
        console.log("Camera turned on");
        // Set a timeout to capture and process the image after 2 seconds
        setTimeout(() => captureAndStoreImage(), 2000);
      } catch (error) {
        console.error('Error accessing camera:', error);
      }
    }
  };

  const captureAndStoreImage = async () => {
    if (!cameraOn) {
      console.log("Camera is not on, cannot capture image");
      return;
    }
  
    console.log("Capturing image");
    const video = videoRef.current;
    const canvas = document.createElement('canvas');
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    canvas.getContext('2d').drawImage(video, 0, 0);
    const imageDataUrl = canvas.toDataURL('image/jpeg');
  
    // Save the image to Firebase Storage
    try {
      const fileName = `captured_image_${Date.now()}.jpg`;
      const storageRef = ref(storage, `gs://futurelabs-c5c3d.appspot.com/assets/${fileName}`);
      
      await uploadString(storageRef, imageDataUrl, 'data_url');
      console.log(`Image uploaded to Firebase Storage as ${fileName}`);
    } catch (error) {
      console.error('Error uploading image to Firebase Storage:', error);
    }
  };

  const togglePlay = () => {
    if (audioRef.current.paused) {
      audioRef.current.play();
      setIsPlaying(true);
    } else {
      audioRef.current.pause();
      setIsPlaying(false);
    }
  };

  const startRecording = async () => {
    if (isPlaying) {
      audioRef.current.pause();
      setIsPlaying(false);
    }

    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const mediaRecorder = new MediaRecorder(stream);
      const audioChunks = [];

      mediaRecorder.ondataavailable = (event) => {
        audioChunks.push(event.data);
      };

      mediaRecorder.onstop = async () => {
        const audioBlob = new Blob(audioChunks, { type: 'audio/mp3' });
        await transcribeAudio(audioBlob);
      };

      mediaRecorder.start();
      setIsRecording(true);

      setTimeout(() => {
        mediaRecorder.stop();
        setIsRecording(false);
      }, 4000); // Record for 4 seconds
    } catch (error) {
      console.error('Error starting recording:', error);
    }
  };

  const transcribeAudio = async (audioBlob) => {
    const formData = new FormData();
    formData.append('file', audioBlob, 'audio.mp3');
    formData.append('model', 'whisper-1');

    try {
      const response = await openai.audio.transcriptions.create({
        file: formData.get('file'),
        model: 'whisper-1',
      });

      const transcribedText = response.text;
      setTranscription(transcribedText);
      onTranscriptionUpdate(transcribedText);
      await generateAssistantResponse(transcribedText);
    } catch (error) {
      console.error('Error transcribing audio:', error);
    }
  };

  const generateAssistantResponse = async (userInput) => {
    try {
      const thread = await openai.beta.threads.create();

      await openai.beta.threads.messages.create(thread.id, {
        role: "user",
        content: userInput
      });

      const run = await openai.beta.threads.runs.create(thread.id, {
        assistant_id: ASSISTANT_ID
      });

      let runStatus = await openai.beta.threads.runs.retrieve(thread.id, run.id);
      while (runStatus.status !== 'completed') {
        await new Promise(resolve => setTimeout(resolve, 1000));
        runStatus = await openai.beta.threads.runs.retrieve(thread.id, run.id);
      }

      const messages = await openai.beta.threads.messages.list(thread.id);

      const lastAssistantMessage = messages.data
        .filter(message => message.role === 'assistant')
        .pop();

      if (lastAssistantMessage) {
        const response = lastAssistantMessage.content[0].text.value;
        console.log("Assistant Response:", response);
        await generateAndPlaySpeech(response);
        executeCommand(userInput, response);
      }
    } catch (error) {
      console.error('Error generating assistant response:', error);
    }
  };

  const generateAndPlaySpeech = async (text) => {
    try {
      console.log("Generating speech for:", text);
      const mp3 = await openai.audio.speech.create({
        model: "tts-1",
        voice: "alloy",
        input: text,
      });

      const blob = new Blob([await mp3.arrayBuffer()], { type: 'audio/mpeg' });
      const url = URL.createObjectURL(blob);
      
      if (ttsAudioRef.current) {
        ttsAudioRef.current.src = url;
        ttsAudioRef.current.play();
      } else {
        const audio = new Audio(url);
        audio.play();
        ttsAudioRef.current = audio;
      }
      console.log("Speech generated and playing");
    } catch (error) {
      console.error('Error generating or playing speech:', error);
    }
  };

  const executeCommand = useCallback((userInput, assistantResponse) => {
    const lowerUserInput = userInput.toLowerCase();
    const lowerAssistantResponse = assistantResponse.toLowerCase();

    console.log("Executing command. User input:", lowerUserInput, "Assistant response:", lowerAssistantResponse);

    const navigationCommands = {
      'home': '/',
      'future food': '/future-food',
      'future maps': '/future-maps',
      'future education': '/future-education',
      'satoshi nakamoto menu': '/satoshi-nakamoto-menu',
    };

    for (const [command, route] of Object.entries(navigationCommands)) {
      if (lowerUserInput.includes(command) || lowerAssistantResponse.includes(command)) {
        console.log("Navigating to:", route);
        navigate(route);
        return;
      }
    }

    performButtonClick(lowerUserInput, lowerAssistantResponse);
  }, [navigate]);

  const performButtonClick = useCallback((userInput, assistantResponse) => {
    const buttons = document.querySelectorAll('button, a');
    buttons.forEach(button => {
      const buttonText = button.textContent.toLowerCase();
      if ((userInput.includes(buttonText) || assistantResponse.includes(buttonText)) && 
          !button.disabled && 
          button.offsetParent !== null) {
        console.log("Clicking button:", buttonText);
        button.click();
        return; // Exit after clicking the first matching button
      }
    });
  }, []);

  return (
    <div className="fixed bottom-6 right-6 z-50 flex flex-col items-end">
      <video ref={videoRef} style={{ display: 'none' }} autoPlay playsInline />
      {analysisResult && (
        <div className="bg-white bg-opacity-10 backdrop-blur-md p-2 rounded-lg mb-4">
          <p className="text-white text-sm">{analysisResult}</p>
        </div>
      )}
      <div className="flex items-center">
        <audio ref={audioRef} loop>
          <source src={audioSrc} type="audio/mpeg" />
          Your browser does not support the audio element.
        </audio>
        <button 
          onClick={togglePlay}
          className="bg-white bg-opacity-10 backdrop-blur-md text-white p-2.5 rounded-full shadow-lg hover:bg-opacity-20 transition-all duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-white focus:ring-opacity-50 mr-2"
          style={{
            width: '44px',
            height: '44px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {isPlaying ? <Pause size={20} /> : <Play size={20} />}
        </button>
        <button 
          onClick={startRecording}
          className="bg-white bg-opacity-10 backdrop-blur-md text-white p-2.5 rounded-full shadow-lg hover:bg-opacity-20 transition-all duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-white focus:ring-opacity-50 mr-2"
          style={{
            width: '44px',
            height: '44px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Mic size={20} color={isRecording ? 'red' : 'white'} />
        </button>
        <button 
          onClick={toggleCamera}
          className="bg-white bg-opacity-10 backdrop-blur-md text-white p-2.5 rounded-full shadow-lg hover:bg-opacity-20 transition-all duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-white focus:ring-opacity-50"
          style={{
            width: '44px',
            height: '44px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Camera size={20} color={cameraOn ? 'green' : 'white'} />
        </button>
      </div>
    </div>
  );
};

export default AudioPlayer;