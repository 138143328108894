import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFunctions, httpsCallable } from "firebase/functions";
import { getFirestore } from "firebase/firestore"; // Add this import

const firebaseConfig = {
  apiKey: "AIzaSyAlSQ6i-zagIc5WVuOFUNjCBPMqgjyCeaw",
  authDomain: "futurelabs-c5c3d.firebaseapp.com",
  projectId: "futurelabs-c5c3d",
  storageBucket: "futurelabs-c5c3d.appspot.com",
  messagingSenderId: "1094828487034",
  appId: "1:1094828487034:web:21fd54292787290bcf66c3",
  measurementId: "G-07M959RN8M"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const functions = getFunctions(app);
const db = getFirestore(app);

export { app, analytics, functions, db };