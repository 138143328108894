import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Link } from 'react-router-dom';
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import { useWallet, useConnection } from '@solana/wallet-adapter-react';
import { SystemProgram, LAMPORTS_PER_SOL, PublicKey, Transaction } from '@solana/web3.js';
import { collection, addDoc, query, where, getDocs, updateDoc, doc, getDoc, setDoc } from 'firebase/firestore';
import { db, functions } from './firebaseConfig';
import { httpsCallable } from 'firebase/functions';
import futurelabsgrey from './assets/futurefoodgrey.png';

const PREDICTION_COST = 10; // $10 for 24 hours access
const MINUTE_COST = 0.2; // $0.2 per minute (or $1 per 5 minutes)
const SOL_PRICE = 184.56; // This should be updated with a real-time price feed in production
const MANAGER_WALLET = new PublicKey('9SPPvSYpijYK6JrHqEqSvD8hnYvvw6D8EKScLwhjHYnc');

const dummyMemecoins = [
  { name: 'DOGE', price: '$0.07', change: '+5.2%' },
  { name: 'SHIB', price: '$0.000008', change: '-2.1%' },
  { name: 'PEPE', price: '$0.000001', change: '+10.5%' },
  { name: 'BONK', price: '$0.000015', change: '+3.7%' },
  { name: 'WOJAK', price: '$0.00005', change: '-1.8%' },
];

const potentialBreakoutCoins = [
  { name: 'MEME', description: 'New meme coin with viral potential' },
  { name: 'FOMO', description: 'Riding on the fear of missing out' },
  { name: 'MOON', description: 'Promising to take holders to the moon' },
];

function MemecorePredictionPage() {
  const wallet = useWallet();
  const { connection } = useConnection();
  const [accessType, setAccessType] = useState(null);
  const [timeRemaining, setTimeRemaining] = useState(0);
  const [predictions, setPredictions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedCoin, setSelectedCoin] = useState(null);
  const [chatMessages, setChatMessages] = useState({});
  const [currentMessage, setCurrentMessage] = useState('');
  const [userPredictions, setUserPredictions] = useState([]);
  const [predictionAmount, setPredictionAmount] = useState('');
  const [predictionType, setPredictionType] = useState('open');
  const chatEndRef = useRef(null);

  const checkExistingAccess = useCallback(async () => {
    if (!wallet.connected) return;
    const userRef = doc(db, "users", wallet.publicKey.toString());
    const userDoc = await getDoc(userRef);
    if (userDoc.exists()) {
      const userData = userDoc.data();
      if (userData.accessExpiry > Date.now()) {
        setAccessType(userData.accessType);
        setTimeRemaining(Math.floor((userData.accessExpiry - Date.now()) / 1000));
      }
    } else {
      // Create user document if it doesn't exist
      await setDoc(userRef, { predictions: [], chatHistory: {} });
    }
  }, [wallet.connected, wallet.publicKey]);

  const fetchUserData = useCallback(async () => {
    if (!wallet.connected) return;
    const userRef = doc(db, "users", wallet.publicKey.toString());
    const userDoc = await getDoc(userRef);
    if (userDoc.exists()) {
      const userData = userDoc.data();
      setUserPredictions(userData.predictions || []);
      setChatMessages(userData.chatHistory || {});
    }
  }, [wallet.connected, wallet.publicKey]);

  const fetchPredictions = useCallback(async () => {
    if (!wallet.connected) return;
    setLoading(true);
    try {
      const predictionsRef = collection(db, "predictions");
      const q = query(predictionsRef, where("visible", "==", true));
      const querySnapshot = await getDocs(q);
      const fetchedPredictions = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setPredictions(fetchedPredictions);
    } catch (error) {
      console.error("Error fetching predictions:", error);
      setError("Failed to fetch predictions. Please try again.");
    } finally {
      setLoading(false);
    }
  }, [wallet.connected]);

  useEffect(() => {
    checkExistingAccess();
    fetchUserData();
    fetchPredictions();
  }, [checkExistingAccess, fetchUserData, fetchPredictions]);

  useEffect(() => {
    let timer;
    if (timeRemaining > 0) {
      timer = setInterval(() => {
        setTimeRemaining(prev => {
          const newTime = prev - 1;
          if (newTime <= 0) {
            clearInterval(timer);
            setAccessType(null);
          }
          return newTime;
        });
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [timeRemaining]);

  const purchaseAccess = async (type) => {
    if (!wallet.connected) {
      alert("Please connect your wallet first.");
      return;
    }

    setLoading(true);
    setError(null);

    try {
      const cost = type === '24h' ? PREDICTION_COST : MINUTE_COST * 5;
      const lamports = Math.round(cost * LAMPORTS_PER_SOL / SOL_PRICE);

      const transaction = new Transaction().add(
        SystemProgram.transfer({
          fromPubkey: wallet.publicKey,
          toPubkey: MANAGER_WALLET,
          lamports: lamports,
        })
      );

      const { blockhash } = await connection.getLatestBlockhash();
      transaction.recentBlockhash = blockhash;
      transaction.feePayer = wallet.publicKey;

      const signed = await wallet.signTransaction(transaction);
      const signature = await connection.sendRawTransaction(signed.serialize());
      await connection.confirmTransaction(signature);

      const accessDuration = type === '24h' ? 24 * 60 * 60 : 5 * 60; // 24 hours or 5 minutes in seconds
      const accessExpiry = Date.now() + accessDuration * 1000;

      await updateDoc(doc(db, "users", wallet.publicKey.toString()), {
        accessType: type,
        accessExpiry: accessExpiry,
      });

      setAccessType(type);
      setTimeRemaining(accessDuration);
      fetchPredictions();
    } catch (error) {
      console.error("Error purchasing access:", error);
      setError("Failed to purchase access. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const sendMessage = async () => {
    if (currentMessage.trim() !== '' && selectedCoin) {
      const newMessage = { text: currentMessage, sender: 'user', timestamp: Date.now() };
      const updatedChatMessages = {
        ...chatMessages,
        [selectedCoin]: [...(chatMessages[selectedCoin] || []), newMessage]
      };
      setChatMessages(updatedChatMessages);
      setCurrentMessage('');

      // Update user's chat history in Firebase
      const userRef = doc(db, "users", wallet.publicKey.toString());
      await updateDoc(userRef, { chatHistory: updatedChatMessages });

      // Simulate AI response
      setTimeout(() => {
        const aiResponse = { text: `Prediction for ${selectedCoin}: ${currentMessage}`, sender: 'ai', timestamp: Date.now() };
        const updatedWithAI = {
          ...updatedChatMessages,
          [selectedCoin]: [...(updatedChatMessages[selectedCoin] || []), aiResponse]
        };
        setChatMessages(updatedWithAI);
        updateDoc(userRef, { chatHistory: updatedWithAI });
      }, 1000);
    }
  };

  const createPrediction = async () => {
    if (!selectedCoin || !predictionAmount || parseFloat(predictionAmount) < 0.2) {
      alert("Please select a coin and enter a valid prediction amount (minimum $0.20).");
      return;
    }

    setLoading(true);
    setError(null);

    try {
      const totalCost = parseFloat(predictionAmount) + 0.1; // Adding $0.10 fee
      const lamports = Math.round(totalCost * LAMPORTS_PER_SOL / SOL_PRICE);

      const transaction = new Transaction().add(
        SystemProgram.transfer({
          fromPubkey: wallet.publicKey,
          toPubkey: MANAGER_WALLET,
          lamports: lamports,
        })
      );

      const { blockhash } = await connection.getLatestBlockhash();
      transaction.recentBlockhash = blockhash;
      transaction.feePayer = wallet.publicKey;

      const signed = await wallet.signTransaction(transaction);
      const signature = await connection.sendRawTransaction(signed.serialize());
      await connection.confirmTransaction(signature);

      const newPrediction = {
        coin: selectedCoin,
        amount: parseFloat(predictionAmount),
        type: predictionType,
        timestamp: Date.now(),
        participants: [wallet.publicKey.toString()],
      };

      const predictionRef = await addDoc(collection(db, "predictions"), newPrediction);

      const userRef = doc(db, "users", wallet.publicKey.toString());
      await updateDoc(userRef, {
        predictions: [...userPredictions, { id: predictionRef.id, ...newPrediction }]
      });

      setUserPredictions(prev => [...prev, { id: predictionRef.id, ...newPrediction }]);
      setPredictionAmount('');
      setPredictionType('open');
      alert("Prediction created successfully!");
    } catch (error) {
      console.error("Error creating prediction:", error);
      setError("Failed to create prediction. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const sharePrediction = (coin, message) => {
    const shareText = encodeURIComponent(`Check out this prediction for ${coin}: ${message}`);
    const shareUrl = `https://twitter.com/intent/tweet?text=${shareText}`;
    window.open(shareUrl, '_blank');
  };

  useEffect(() => {
    chatEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [chatMessages]);

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-900 to-black text-white font-sans overflow-hidden">
      <div className="relative z-10 flex flex-col min-h-screen max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <header className="py-6 sm:py-10 flex justify-between items-center border-b border-gray-800">
          <img 
            src={futurelabsgrey} 
            alt="Future Predictions Logo" 
            className="w-24 h-auto sm:w-[120px] sm:h-[76px] object-contain"
          />
          <Link to="/future-predictions" className="text-blue-400 hover:text-blue-300 transition-colors duration-200 text-base sm:text-lg">
            Back to Future Predictions
          </Link>
        </header>

        <main className="flex-grow flex flex-col items-center justify-start py-8 space-y-8">
          <h1 className="text-3xl sm:text-4xl font-bold text-center bg-clip-text text-transparent bg-gradient-to-r from-purple-400 to-pink-600">
            Meme Core Prediction Engine
          </h1>
          
          {/* Memecoin Bubbles */}
          <div className="w-full max-w-4xl">
            <h2 className="text-2xl font-semibold mb-4 text-center">Top Memecoins</h2>
            <div className="flex flex-wrap justify-center gap-4">
              {dummyMemecoins.map((coin) => (
                <button
                  key={coin.name}
                  onClick={() => setSelectedCoin(coin.name)}
                  className={`bg-gradient-to-r from-purple-500 to-indigo-500 p-4 rounded-full flex flex-col items-center transition-all transform hover:scale-105 ${selectedCoin === coin.name ? 'ring-2 ring-yellow-400' : ''}`}
                >
                  <span className="font-bold">{coin.name}</span>
                  <span>{coin.price}</span>
                  <span className={coin.change.startsWith('+') ? 'text-green-300' : 'text-red-300'}>{coin.change}</span>
                </button>
              ))}
            </div>
          </div>

          <div className="wallet-connection">
            <WalletMultiButton />
          </div>

          {wallet.connected ? (
            <>
              {accessType ? (
                <div className="bg-gray-800 bg-opacity-50 backdrop-filter backdrop-blur-lg p-6 rounded-lg w-full max-w-2xl">
                  <p className="text-lg">Access Type: <span className="font-semibold">{accessType === '24h' ? '24-hour' : '5-minute'}</span></p>
                  <p className="text-lg">Time Remaining: <span className="font-semibold">{Math.floor(timeRemaining / 60)}m {timeRemaining % 60}s</span></p>
                </div>
              ) : (
                <div className="flex flex-col sm:flex-row gap-4">
                  <button 
                    onClick={() => purchaseAccess('24h')} 
                    className="bg-blue-600 hover:bg-blue-700 px-6 py-3 rounded-lg text-white text-sm sm:text-base shadow-lg hover:shadow-xl transition-all duration-200"
                  >
                    Purchase 24-hour access for $10
                  </button>
                  <button 
                    onClick={() => purchaseAccess('5min')} 
                    className="bg-green-600 hover:bg-green-700 px-6 py-3 rounded-lg text-white text-sm sm:text-base shadow-lg hover:shadow-xl transition-all duration-200"
                  >
                    Purchase 5-minute access for $1
                  </button>
                </div>
              )}

{selectedCoin && (
                <div className="w-full max-w-2xl space-y-4">
                  <h2 className="text-2xl font-semibold">Create Prediction for {selectedCoin}</h2>
                  <div className="flex flex-col sm:flex-row gap-4">
                    <input
                      type="number"
                      value={predictionAmount}
                      onChange={(e) => setPredictionAmount(e.target.value)}
                      placeholder="Amount (min $0.20)"
                      className="flex-grow bg-gray-700 rounded-lg p-2 text-white"
                      min="0.20"
                      step="0.01"
                    />
                    <select
                      value={predictionType}
                      onChange={(e) => setPredictionType(e.target.value)}
                      className="bg-gray-700 rounded-lg p-2 text-white"
                    >
                      <option value="open">Open Market</option>
                      <option value="bet">Price Bet</option>
                    </select>
                    <button
                      onClick={createPrediction}
                      className="bg-purple-600 hover:bg-purple-700 px-6 py-2 rounded-lg text-white shadow-lg hover:shadow-xl transition-all duration-200"
                    >
                      Create Prediction
                    </button>
                  </div>
                </div>
              )}

              {/* Chat Interface */}
              <div className="w-full max-w-2xl bg-gray-800 bg-opacity-50 backdrop-filter backdrop-blur-lg rounded-lg p-6">
                <h2 className="text-2xl font-semibold mb-4">Prediction Chat</h2>
                <div className="h-64 overflow-y-auto mb-4 p-2 bg-gray-900 rounded-lg">
                  {selectedCoin && chatMessages[selectedCoin]?.map((msg, index) => (
                    <div key={index} className={`mb-2 ${msg.sender === 'user' ? 'text-right' : 'text-left'}`}>
                      <span className={`inline-block p-2 rounded-lg ${msg.sender === 'user' ? 'bg-blue-600' : 'bg-gray-600'}`}>
                        {msg.text}
                      </span>
                      {msg.sender === 'user' && (
                        <button
                          onClick={() => sharePrediction(selectedCoin, msg.text)}
                          className="ml-2 text-xs bg-green-500 hover:bg-green-600 px-2 py-1 rounded transition-colors duration-200"
                        >
                          Share
                        </button>
                      )}
                    </div>
                  ))}
                  <div ref={chatEndRef} />
                </div>
                <div className="flex">
                  <input
                    type="text"
                    value={currentMessage}
                    onChange={(e) => setCurrentMessage(e.target.value)}
                    className="flex-grow bg-gray-700 rounded-l-lg p-2 text-white"
                    placeholder="Enter your prediction..."
                    disabled={!selectedCoin}
                  />
                  <button
                    onClick={sendMessage}
                    className="bg-blue-600 hover:bg-blue-700 text-white px-4 py-2 rounded-r-lg transition-colors duration-200"
                    disabled={!selectedCoin}
                  >
                    Send
                  </button>
                </div>
              </div>

              {/* User Predictions */}
              <div className="w-full max-w-2xl">
                <h2 className="text-2xl font-semibold mb-4">Your Predictions</h2>
                {userPredictions.map((pred, index) => (
                  <div key={index} className="bg-gray-800 bg-opacity-50 backdrop-filter backdrop-blur-lg p-4 rounded-lg mb-2">
                    <p><strong>{pred.coin}:</strong> ${pred.amount} - {pred.type}</p>
                    <p className="text-sm text-gray-400">Created: {new Date(pred.timestamp).toLocaleString()}</p>
                  </div>
                ))}
              </div>

              {/* Potential Breakout Coins */}
              <div className="w-full max-w-2xl">
                <h2 className="text-2xl font-semibold mb-4">Potential Breakout Coins</h2>
                {potentialBreakoutCoins.map((coin, index) => (
                  <div key={index} className="bg-gray-800 bg-opacity-50 backdrop-filter backdrop-blur-lg p-4 rounded-lg mb-2">
                    <h3 className="font-bold text-lg">{coin.name}</h3>
                    <p>{coin.description}</p>
                  </div>
                ))}
              </div>
            </>
          ) : (
            <p className="text-center text-xl">Please connect your wallet to access predictions.</p>
          )}

          {loading && (
            <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50 z-50">
              <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-purple-500"></div>
            </div>
          )}
          {error && <p className="text-center mt-4 text-red-500">{error}</p>}
        </main>

        <footer className="py-6 sm:py-10 border-t border-gray-800">
          <p className="text-gray-400 text-sm sm:text-base mb-2">© 2024 Future Labs.</p>
          <p className="text-gray-400 text-sm sm:text-base max-w-[300px]">
            Mission: We want to help ourselves and others enjoy art and science while having not a good life, but a fucking great one!
          </p>
        </footer>
      </div>
    </div>
  );
}

export default MemecorePredictionPage;