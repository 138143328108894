import React, { useState, useEffect, useMemo } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import { ConnectionProvider, WalletProvider } from '@solana/wallet-adapter-react';
import { WalletAdapterNetwork } from '@solana/wallet-adapter-base';
import {
    PhantomWalletAdapter,
    SolflareWalletAdapter,
    TorusWalletAdapter,
    LedgerWalletAdapter,
} from '@solana/wallet-adapter-wallets';
import { WalletModalProvider } from '@solana/wallet-adapter-react-ui';
import { clusterApiUrl } from '@solana/web3.js';
import io from 'socket.io-client';

import AudioPlayer from './AudioPlayer';
import FutureFoodPage from './FutureFoodPage';
import { FuturePredictionsPage } from './FuturePredictionsPage';
import FutureIdeasPage from './FutureIdeasPage';
import MemecorePredictionPage from './MemecorePredictionPage';
import SatoshiNakamotoMenuPage from './SatoshiNakamotoMenuPage';
import FutureEnergyPage from './FutureEnergyPage';  // Add this line to import the new component

import woah from './assets/woahbylilbaby.mp3';
import backgroundVideo from './assets/backvid.mp4';
import blackandwhite from './assets/futurelabsblackandwhite.png';

import './App.css';

// Default styles that can be overridden by your app
require('@solana/wallet-adapter-react-ui/styles.css');

const socket = io('http://localhost:3001');

function Footer() {
  return (
    <footer className="p-6 sm:p-10 md:px-20 md:py-6 flex flex-col items-start">
      <p className="text-[#535353] text-xs sm:text-[15px] mb-2 text-left">© 2024 Future Labs.</p>
      <p className="text-[#535353] text-xs sm:text-[15px] max-w-[300px] text-left">
        Mission: We want to help ourselves and others enjoy art and science while having not a good life, but a fucking great one!
      </p>
    </footer>
  );
}

function HomePage({ users }) {
  return (
    <div className="min-h-screen bg-black text-white krona-one-regular flex flex-col relative overflow-hidden">
      <video
        autoPlay
        loop
        muted
        playsInline
        className="absolute inset-0 w-full h-full object-cover opacity-50"
        style={{ objectFit: 'cover' }}
      >
        <source src={backgroundVideo} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div className="relative z-10 flex flex-col min-h-screen">
        <header className="p-6 sm:p-10 md:p-20 flex justify-between items-start">
          <img 
            src={blackandwhite} 
            alt="Future Labs Logo" 
            className="w-32 h-auto sm:w-[144px] sm:h-[76px] object-contain"
          />
          <div className="flex flex-col items-end space-y-2">
            {Object.entries(users).map(([userId, user]) => (
              user.stream && (
                <div key={userId} className="relative w-32 h-24 sm:w-48 sm:h-36 bg-black rounded-lg overflow-hidden">
                  <video
                    ref={el => {
                      if (el) el.srcObject = user.stream;
                    }}
                    autoPlay
                    playsInline
                    className="absolute inset-0 w-full h-full object-cover"
                  />
                </div>
              )
            ))}
          </div>
        </header>
        <main className="flex-grow flex flex-col items-center justify-center p-4">
          <p className="text-sm sm:text-lg mb-6 sm:mb-10">/Labs</p>
          <Link 
            to="/future-food"
            className="bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg w-40 sm:w-[200px] h-10 sm:h-[55px] rounded-[11px] flex items-center justify-center shadow-[inset_0_1px_0_0_rgba(255,255,255,0.1)] hover:shadow-[inset_0_1px_0_0_rgba(255,255,255,0.2)] transition-all duration-300 ease-in-out mb-4"
            style={{
              boxShadow: '8px 8px 16px rgba(0, 0, 0, 0.2), -8px -8px 16px rgba(255, 255, 255, 0.05)'
            }}
          >
            <span className="text-white text-sm sm:text-lg">Future Food</span>
          </Link>
          {/* <Link 
            to="/future-predictions"
            className="bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg w-56 sm:w-[280px] h-10 sm:h-[55px] rounded-[11px] flex items-center justify-center shadow-[inset_0_1px_0_0_rgba(255,255,255,0.1)] hover:shadow-[inset_0_1px_0_0_rgba(255,255,255,0.2)] transition-all duration-300 ease-in-out mb-4"
            style={{
              boxShadow: '8px 8px 16px rgba(0, 0, 0, 0.2), -8px -8px 16px rgba(255, 255, 255, 0.05)'
            }}
          >
            <span className="text-white text-sm sm:text-lg">Future Predictions</span>
          </Link> */}
          <Link 
            to="/future-ideas"
            className="bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg w-40 sm:w-[200px] h-10 sm:h-[55px] rounded-[11px] flex items-center justify-center shadow-[inset_0_1px_0_0_rgba(255,255,255,0.1)] hover:shadow-[inset_0_1px_0_0_rgba(255,255,255,0.2)] transition-all duration-300 ease-in-out mb-4"
            style={{
              boxShadow: '8px 8px 16px rgba(0, 0, 0, 0.2), -8px -8px 16px rgba(255, 255, 255, 0.05)'
            }}
          >
            <span className="text-white text-sm sm:text-lg">Future Ideas</span>
          </Link>
          {/* <Link 
            to="/future-energy"
            className="bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg w-40 sm:w-[200px] h-10 sm:h-[55px] rounded-[11px] flex items-center justify-center shadow-[inset_0_1px_0_0_rgba(255,255,255,0.1)] hover:shadow-[inset_0_1px_0_0_rgba(255,255,255,0.2)] transition-all duration-300 ease-in-out mb-4"
            style={{
              boxShadow: '8px 8px 16px rgba(0, 0, 0, 0.2), -8px -8px 16px rgba(255, 255, 255, 0.05)'
            }}
          >
            <span className="text-white text-sm sm:text-lg">Future Energy</span>
          </Link> */}
        </main>
        <Footer />
      </div>
    </div>
  );
}

function App() {
  const [transcription, setTranscription] = useState('');
  const [users, setUsers] = useState({});

  // Can be set to 'devnet', 'testnet', or 'mainnet-beta'
  const network = WalletAdapterNetwork.Devnet;

  // You can also provide a custom RPC endpoint
  const endpoint = useMemo(() => clusterApiUrl(network), [network]);

  // @solana/wallet-adapter-wallets includes all the adapters but supports tree shaking and lazy loading --
  // Only the wallets you configure here will be compiled into your application, and only the dependencies
  // of wallets that your users connect to will be loaded
  const wallets = useMemo(
    () => [
        new PhantomWalletAdapter(),
        new SolflareWalletAdapter(),
        new TorusWalletAdapter(),
        new LedgerWalletAdapter(),
    ],
    []
  );

  useEffect(() => {
    socket.on('user-connected', (userId) => {
      setUsers(prevUsers => ({
        ...prevUsers,
        [userId]: { stream: null }
      }));
    });

    socket.on('user-disconnected', (userId) => {
      setUsers(prevUsers => {
        const newUsers = { ...prevUsers };
        delete newUsers[userId];
        return newUsers;
      });
    });

    return () => {
      socket.off('user-connected');
      socket.off('user-disconnected');
    };
  }, []);

  const handleTranscriptionUpdate = (newTranscription) => {
    setTranscription(newTranscription);
  };

  const handleVideoStreamChange = (stream) => {
    const myUserId = socket.id;
    setUsers(prevUsers => ({
      ...prevUsers,
      [myUserId]: { ...prevUsers[myUserId], stream }
    }));
    
    if (stream) {
      socket.emit('start-stream', myUserId);
    } else {
      socket.emit('stop-stream', myUserId);
    }
  };

  return (
    <ConnectionProvider endpoint={endpoint}>
      <WalletProvider wallets={wallets} autoConnect>
        <WalletModalProvider>
          <Router>
            <div className="App">
              <AudioPlayer 
                audioSrc={woah} 
                onTranscriptionUpdate={handleTranscriptionUpdate}
                onVideoStreamChange={handleVideoStreamChange}
              />
              {transcription && (
                <div className="fixed top-4 right-4 z-50 bg-black bg-opacity-50 text-white p-4 rounded-lg max-w-md max-h-48 overflow-y-auto">
                  <h3 className="text-lg font-semibold mb-2">Transcription:</h3>
                  <p className="text-sm">{transcription}</p>
                </div>
              )}
              <Routes>
                <Route path="/" element={<HomePage users={users} />} />
                <Route path="/future-food" element={<FutureFoodPage />} />
                <Route path="/satoshi-nakamoto-menu" element={<SatoshiNakamotoMenuPage />} />
                <Route path="/future-predictions" element={<FuturePredictionsPage />} />
                <Route path="/memecore-prediction" element={<MemecorePredictionPage />} />
                <Route path="/future-ideas" element={<FutureIdeasPage />} />
                {/* <Route path="/future-energy" element={<FutureEnergyPage />} /> */}
              </Routes>
            </div>
          </Router>
        </WalletModalProvider>
      </WalletProvider>
    </ConnectionProvider>
  );
}

export default App;