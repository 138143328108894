import React, { useRef, useState, useEffect } from 'react';
import { Link, useLocation,useNavigate } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import { getFunctions, httpsCallable } from 'firebase/functions';
import kasto from './assets/katsoSando.mp4';

const stripePromise = loadStripe('pk_live_51JVeDoDAsc6s9Fznh5RE7xeTaMgKil8GlyTbrcGQbYqsCoaXfZKALdQYD2F8mHbydrH8WfvhNxqyGWRGu5OY1oVY00M7JS8yql');

function OrderForm({ onSubmit }) {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [address, setAddress] = useState('');
  const [paymentMethod, setPaymentMethod] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsProcessing(true);
    setError('');

    try {
      const response = await fetch('https://us-central1-futurelabs-c5c3d.cloudfunctions.net/createCheckoutSession', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name,
          email,
          phone,
          address,
          paymentMethod,
          amount: 100, // $100.00 in cents
          origin: window.location.origin
        }),
      });

      const result = await response.json();
      console.log('Function result:', result);

      if (result.sessionId) {
        const stripe = await stripePromise;
        const { error } = await stripe.redirectToCheckout({
          sessionId: result.sessionId,
        });

        if (error) {
          console.error('Stripe redirect error:', error);
          throw new Error(error.message);
        }
      } else {
        throw new Error('Invalid response from server');
      }
    } catch (error) {
      console.error('Payment processing error:', error);
      setError(`Payment processing failed: ${error.message}. Please try again.`);
    } finally {
      setIsProcessing(false);
    }
  };

  const paymentOptions = {
    'Credit/Debit Card': ['Visa', 'Mastercard'],
    'Cryptocurrency': ['Solana'],
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <input
        type="text"
        value={name}
        onChange={(e) => setName(e.target.value)}
        placeholder="Name"
        required
        className="w-full p-2 bg-white bg-opacity-10 rounded text-white text-sm"
      />
      <input
        type="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        placeholder="Email (required if no phone)"
        className="w-full p-2 bg-white bg-opacity-10 rounded text-white text-sm"
      />
      <input
        type="tel"
        value={phone}
        onChange={(e) => setPhone(e.target.value)}
        placeholder="Phone (required if no email)"
        className="w-full p-2 bg-white bg-opacity-10 rounded text-white text-sm"
      />
      <input
        type="text"
        value={address}
        onChange={(e) => setAddress(e.target.value)}
        placeholder="Delivery Address"
        required
        className="w-full p-2 bg-white bg-opacity-10 rounded text-white text-sm"
      />
      <select
        value={paymentMethod}
        onChange={(e) => setPaymentMethod(e.target.value)}
        required
        className="w-full p-2 bg-white bg-opacity-10 rounded text-white text-sm"
      >
        <option value="">Select Payment Method</option>
        {Object.entries(paymentOptions).map(([category, methods]) => (
          <optgroup key={category} label={category}>
            {methods.map((method) => (
              <option key={method} value={method}>
                {method}
              </option>
            ))}
          </optgroup>
        ))}
      </select>
      {error && <p className="text-red-500 text-sm">{error}</p>}
      <button
        type="submit"
        disabled={isProcessing}
        className="w-full bg-white bg-opacity-10 text-white p-2 rounded text-sm shadow-[inset_0_1px_0_0_rgba(255,255,255,0.1)] hover:shadow-[inset_0_1px_0_0_rgba(255,255,255,0.2)] transition-all duration-300 ease-in-out"
        style={{
          boxShadow: '4px 4px 8px rgba(0, 0, 0, 0.2), -4px -4px 8px rgba(255, 255, 255, 0.05)'
        }}
      >
        {isProcessing ? 'Processing...' : 'Place Order'}
      </button>
    </form>
  );
}

function OrderConfirmation({ sessionId }) {
  const [orderDetails, setOrderDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const getOrderDetails = httpsCallable(getFunctions(), 'getOrderDetails');
    
    const fetchOrderDetails = async () => {
      try {
        const result = await getOrderDetails({ sessionId });
        setOrderDetails(result.data);
      } catch (error) {
        console.error('Error fetching order details:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchOrderDetails();
  }, [sessionId]);

  if (isLoading) {
    return <div>Loading order details...</div>;
  }

  if (!orderDetails) {
    return <div>Error loading order details. Please contact support.</div>;
  }

  return (
    <div className="bg-white bg-opacity-10 p-4 rounded-lg">
      <h3 className="text-xl mb-4">Order Confirmed!</h3>
      <p>Your Wagyu Beef Katsu Sandwich is being prepared and will be delivered soon.</p>
      <div className="mt-4">
        <h4 className="font-bold">Order Details:</h4>
        <p>Order ID: {orderDetails.orderId}</p>
        <p>Total: ${orderDetails.amount / 100}</p>
        <p>Delivery Address: {orderDetails.address}</p>
      </div>
      <p className="mt-4">A confirmation email has been sent to your email address.</p>
    </div>
  );
}

function SatoshiNakamotoMenuPage() {
  const [paymentSessionId, setPaymentSessionId] = useState(null);
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(true);
  const [orderStep, setOrderStep] = useState(0);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.play().catch(error => {
        console.error("Error attempting to play video:", error);
      });
    }

    // Check for Stripe redirect result
    const query = new URLSearchParams(location.search);
    const sessionId = query.get('session_id');
    const canceled = query.get('canceled');

    if (sessionId) {
      setPaymentSessionId(sessionId);
      setOrderStep(2);
    } else if (canceled) {
      setOrderStep(0);
      // You might want to show a message that the order was canceled
    }
  }, [location]);

  useEffect(() => {
    if (orderStep === 2) {
      // Clear the URL parameters after a short delay
      const timer = setTimeout(() => {
        navigate('/future-food/satoshi-nakamoto-menu', { replace: true });
      }, 100);
      return () => clearTimeout(timer);
    }
  }, [orderStep, navigate]);

  const handleMouseEnter = () => {
    if (videoRef.current && isPlaying) {
      videoRef.current.pause();
      setIsPlaying(false);
    }
  };

  const handleMouseLeave = () => {
    if (videoRef.current && !isPlaying) {
      videoRef.current.play().catch(error => {
        console.error("Error attempting to play video:", error);
      });
      setIsPlaying(true);
    }
  };

  const handleOrderSubmit = (orderData) => {
    console.log('Order submitted:', orderData);
    setOrderStep(1);
  };

  const renderOrderContent = () => {
    switch(orderStep) {
      case 1:
        return <OrderForm onSubmit={handleOrderSubmit} />;
      case 2:
        return <OrderConfirmation sessionId={paymentSessionId} />;
      default:
        return (
          <button
            onClick={() => setOrderStep(1)}
            className="bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg text-white text-sm w-full sm:w-auto px-4 sm:px-6 py-2 sm:py-3 rounded-lg shadow-[inset_0_1px_0_0_rgba(255,255,255,0.1)] hover:shadow-[inset_0_1px_0_0_rgba(255,255,255,0.2)] transition-all duration-300 ease-in-out"
            style={{
              boxShadow: '4px 4px 8px rgba(0, 0, 0, 0.2), -4px -4px 8px rgba(255, 255, 255, 0.05)'
            }}
          >
            Order Delivery
          </button>
        );
    }
  };

  return (
    <div className="min-h-screen bg-black text-white krona-one-regular flex flex-col">
      <header className="p-6 sm:p-10 md:p-20 flex justify-between items-center">
        <h1 className="text-base sm:text-lg">
          <span className="text-gray-500">FF</span>
          <span className="text-white"> / Satoshi Nakamoto</span>
        </h1>
        <Link to="/future-food" className="text-gray-500 text-base sm:text-lg">
          By Future Food
        </Link>
      </header>
      <main className="flex-grow flex flex-col lg:flex-row justify-start items-start p-6 sm:p-10 md:px-20 md:py-6 gap-8">
        <h2 className="text-white text-2xl sm:text-3xl md:text-4xl mb-6 lg:hidden w-full">Wagyu Beef Katsu Sandwich</h2>
        <div className="w-full lg:w-3/5 xl:w-2/3 lg:order-1 mb-6 lg:mb-0">
          <div className="relative" style={{ paddingTop: '56.25%' }}>
            <video
              ref={videoRef}
              className="absolute top-0 left-0 w-full h-full object-cover"
              autoPlay
              loop
              muted
              playsInline
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              <source src={kasto} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
          </div>
        <div className="w-full lg:w-2/5 xl:w-1/3 lg:order-0">
          <h2 className="text-white text-2xl sm:text-3xl md:text-4xl mb-6 hidden lg:block">Wagyu Beef Katsu Sandwich</h2>
          <div className="mb-6">
            <h3 className="text-lg mb-3">Ingredients</h3>
            <ul className="text-xs sm:text-sm mb-6">
              <li className="text-blue-500">AS Miyazaki Wagyu Beef,</li>
              <li>Shokupan Bread,</li>
              <li>Panko Bread Crumbs,</li>
              <li>Eggs, Flour, Oil, Tonkatsu Sauce</li>
              <li>Salt & Pepper, Cabbage, Butter</li>
            </ul>
          </div>
          <div className="flex flex-wrap justify-center gap-2 mb-6">
            <button className="bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg px-3 py-1 rounded-[11px] text-white text-xs shadow-[inset_0_1px_0_0_rgba(255,255,255,0.1)] hover:shadow-[inset_0_1px_0_0_rgba(255,255,255,0.2)] transition-all duration-300 ease-in-out"
              style={{
                boxShadow: '4px 4px 8px rgba(0, 0, 0, 0.2), -4px -4px 8px rgba(255, 255, 255, 0.05)'
              }}
            >
              $100.00
            </button>
            <button className="bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg px-3 py-1 rounded-[11px] text-white text-xs shadow-[inset_0_1px_0_0_rgba(255,255,255,0.1)] hover:shadow-[inset_0_1px_0_0_rgba(255,255,255,0.2)] transition-all duration-300 ease-in-out"
              style={{
                boxShadow: '4px 4px 8px rgba(0, 0, 0, 0.2), -4px -4px 8px rgba(255, 255, 255, 0.05)'
              }}
            >
              RealNFT
            </button>
            <button className="bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg px-3 py-1 rounded-[11px] text-white text-xs shadow-[inset_0_1px_0_0_rgba(255,255,255,0.1)] hover:shadow-[inset_0_1px_0_0_rgba(255,255,255,0.2)] transition-all duration-300 ease-in-out"
              style={{
                boxShadow: '4px 4px 8px rgba(0, 0, 0, 0.2), -4px -4px 8px rgba(255, 255, 255, 0.05)'
              }}
            >
              DigitalNFT
            </button>
          </div>
          {renderOrderContent()}
        </div>
      </main>
      <footer className="p-6 sm:p-10 md:px-20 md:py-6 flex justify-between items-end">
        <p className="text-[#535353] text-xs sm:text-[15px]">© 2024 Future Labs.</p>
      </footer>
    </div>
  );
}

export default SatoshiNakamotoMenuPage;