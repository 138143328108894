import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import futurelabsgrey from './assets/futurefoodgrey.png';
import satoshiModel from './satoshi.glb';
import mapColTexture from './assets/Map-COL.jpg';
import mapSpecTexture from './assets/Map-SPEC.jpg';

function FutureFoodPage() {
  const canvasRef = useRef(null);
  const sceneRef = useRef(null);
  const cameraRef = useRef(null);
  const rendererRef = useRef(null);
  const modelRef = useRef(null);
  const [error, setError] = useState(null);
  const [modelLoadingStatus, setModelLoadingStatus] = useState('Loading');
  const [modelInfo, setModelInfo] = useState(null);

  useEffect(() => {
    if (!canvasRef.current) return;

    const scene = new THREE.Scene();
    sceneRef.current = scene;

    const aspectRatio = window.innerWidth / window.innerHeight;
    const camera = new THREE.PerspectiveCamera(75, aspectRatio, 0.1, 1000);
    camera.position.z = 3;
    cameraRef.current = camera;

    const renderer = new THREE.WebGLRenderer({ canvas: canvasRef.current, alpha: true, antialias: true });
    renderer.setSize(window.innerWidth, window.innerHeight);
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
    renderer.outputColorSpace = THREE.SRGBColorSpace;
    renderer.toneMapping = THREE.ACESFilmicToneMapping;
    renderer.toneMappingExposure = 1;
    rendererRef.current = renderer;

    const ambientLight = new THREE.AmbientLight(0xffffff, 0.5);
    scene.add(ambientLight);
    const directionalLight = new THREE.DirectionalLight(0xffffff, 1);
    directionalLight.position.set(5, 5, 5);
    scene.add(directionalLight);

    const textureLoader = new THREE.TextureLoader();
    const colorTexture = textureLoader.load(mapColTexture, () => {
      colorTexture.colorSpace = THREE.SRGBColorSpace;
      updateAllMaterials();
    });
    const specularTexture = textureLoader.load(mapSpecTexture, () => {
      specularTexture.colorSpace = THREE.SRGBColorSpace;
      updateAllMaterials();
    });

    const loader = new GLTFLoader();
    loader.load(
      satoshiModel,
      (gltf) => {
        const model = gltf.scene;
        model.position.set(0, -0.3, 0);
        model.rotation.y = Math.PI;
        scene.add(model);
        modelRef.current = model;

        updateModelScale();

        model.traverse((node) => {
          if (node.isMesh && node.material) {
            const material = new THREE.MeshStandardMaterial({
              map: colorTexture,
              specularMap: specularTexture,
            });
            
            if (node.material.color) material.color.copy(node.material.color);
            if (node.material.roughness !== undefined) material.roughness = node.material.roughness;
            if (node.material.metalness !== undefined) material.metalness = node.material.metalness;

            node.material = material;
          }
        });

        updateAllMaterials();
        setModelLoadingStatus('Loaded with Textures');
        console.log('Model loaded successfully with textures', model);

        const modelInfo = {
          children: model.children.length,
          materials: model.children.map(child => child.material ? child.material.type : 'No material'),
          geometries: model.children.map(child => child.geometry ? child.geometry.type : 'No geometry')
        };
        setModelInfo(modelInfo);
        console.log('Model info:', modelInfo);
      },
      (progress) => {
        console.log((progress.loaded / progress.total * 100) + '% loaded');
      },
      (error) => {
        console.error('Error loading model:', error);
        setError(`Failed to load the 3D model: ${error.message}`);
        setModelLoadingStatus('Failed');
      }
    );

    function updateAllMaterials() {
      scene.traverse((child) => {
        if (child.isMesh && child.material) {
          child.material.needsUpdate = true;
        }
      });
    }

    function updateModelScale() {
      if (modelRef.current) {
        const baseScale = 0.7;
        const aspectRatio = window.innerWidth / window.innerHeight;
        const scaleMultiplier = aspectRatio < 1 ? aspectRatio : 1;
        modelRef.current.scale.set(baseScale * scaleMultiplier, baseScale * scaleMultiplier, baseScale * scaleMultiplier);
      }
    }

    function animate() {
      requestAnimationFrame(animate);
      if (modelRef.current) {
        modelRef.current.rotation.y += 0.005;
      }
      renderer.render(scene, camera);
    }
    animate();

    function handleResize() {
      const width = window.innerWidth;
      const height = window.innerHeight;
      camera.aspect = width / height;
      camera.updateProjectionMatrix();
      renderer.setSize(width, height);
      renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
      updateModelScale();
    }
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      scene.traverse((object) => {
        if (object.geometry) object.geometry.dispose();
        if (object.material) {
          if (object.material.map) object.material.map.dispose();
          object.material.dispose();
        }
      });
      renderer.dispose();
    };
  }, []);

  return (
    <div className="relative min-h-screen bg-black text-white krona-one-regular overflow-hidden">
      <canvas ref={canvasRef} className="absolute inset-0 z-0" />
      
      <div id="content-area" className="relative z-10 flex flex-col min-h-screen">
        {/* Debug information is now hidden but still available in state */}
        {/* Uncomment these lines to show debug info again
        {error && <div className="absolute top-0 left-0 bg-red-500 text-white p-2">{error}</div>}
        <div className="absolute top-0 right-0 bg-blue-500 text-white p-2">Model status: {modelLoadingStatus}</div>
        
        {modelInfo && (
          <div className="absolute top-12 right-0 bg-green-500 text-white p-2">
            <p>Children: {modelInfo.children}</p>
            <p>Materials: {modelInfo.materials.join(', ')}</p>
            <p>Geometries: {modelInfo.geometries.join(', ')}</p>
          </div>
        )}
        */}
        
        <header className="p-6 sm:p-10 md:p-20 flex justify-between items-center">
          <img 
            src={futurelabsgrey} 
            alt="Future Food Logo" 
            className="w-24 h-auto sm:w-[120px] sm:h-[76px] object-contain"
          />
          <Link to="/" className="text-gray-500 text-base sm:text-lg">
            By Future Labs
          </Link>
        </header>

        <main className="flex-grow flex flex-col items-center justify-center p-4">
          <p className="text-sm sm:text-lg mb-6 sm:mb-10">/Menus</p>
          <Link 
            to="/satoshi-nakamoto-menu"
            className="bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg w-56 sm:w-[280px] h-10 sm:h-[55px] rounded-[11px] flex items-center justify-center shadow-[inset_0_1px_0_0_rgba(255,255,255,0.1)] hover:shadow-[inset_0_1px_0_0_rgba(255,255,255,0.2)] transition-all duration-300 ease-in-out"
            style={{
              boxShadow: '8px 8px 16px rgba(0, 0, 0, 0.2), -8px -8px 16px rgba(255, 255, 255, 0.05)'
            }}
          >
            <span className="text-white text-sm sm:text-lg">Satoshi Nakamoto</span>
          </Link>
        </main>

        <Footer />
      </div>
    </div>
  );
}

function Footer() {
  return (
    <footer className="p-6 sm:p-10 md:px-20 md:py-6 flex flex-col items-start">
      <p className="text-[#535353] text-xs sm:text-[15px] mb-2 text-left">© 2024 Future Labs.</p>
      <p className="text-[#535353] text-xs sm:text-[15px] max-w-[300px] text-left">
        Mission: We want to help ourselves and others enjoy art and science while having not a good life, but a fucking great one!
      </p>
    </footer>
  );
}


export default FutureFoodPage;